import React from 'react';

import { Button, Dropdown, Icons, Item, Stack } from '@hypersay/ui';

import { withPrefix } from 'gatsby';

import { Link } from '../Link';

import T from '../../components/T';

import {
  getAppSiteUrl,
  // navigateToLiveWebsite,
  // getCurrentWebsiteUrl,
} from '../../util/navigate-to-app-site';

// tslint:disable-next-line: no-submodule-imports
// import { Size } from '@hypersay/ui/dist/components/globals';
import LoginModal from './../LoginModal';
import * as styles from './header.module.scss';

const HypersayLogo = () => (
  <div className={styles.headerLogo}>
    {/* <div className={styles.blob} /> */}
    <Link to="/">
      <img
        src={withPrefix('/svg/hypersay-logo-spaced3.svg')}
        alt="hypersay logo"
      />
    </Link>
  </div>
);

// const joinButton = (size: Size) => (
//   <Button
//     onClick={() => navigateToLiveWebsite()}
//     label={<T id="Join Session" />}
//     variant="secondary"
//     outline={true}
//     size={size}
//     theme="dark"
//   />
// );

const MenuDesktop = () => {
  // const currentUrl = getCurrentWebsiteUrl();
  return (
    <Stack
      direction="horizontal"
      gap="1rem"
      className={`${styles.header} ${styles.forDesktopOnly}`}
    >
      <div className={styles.logoAndBlog}>
        <HypersayLogo />
        <h3>
          <T id="blog" />
        </h3>
      </div>
      <Link to="/blog/guide/" activeClassName={styles.activePage}>
        <Button
          label={<T id="Guide" />}
          variant="glass"
          size="s"
          theme="dark"
        />
      </Link>
      <Link to="/blog/tips/" activeClassName={styles.activePage}>
        <Button
          label={<T id="Tips & Tricks" />}
          variant="glass"
          size="s"
          theme="dark"
        />
      </Link>
      {/* <Link to="/blog/celebrations/" activeClassName={styles.activePage}>
        <Button
          label={<T id="Celebrations" />}
          variant="glass"
          size="s"
          theme="dark"
        />
      </Link> */}
      {/* <Link to="/blog/speech/" activeClassName={styles.activePage}>
        <Button
          label={<T id="HyperSpeech" />}
          variant="glass"
          size="s"
          theme="dark"
        />
      </Link> */}
      <Dropdown
        trigger={
          <Button
            size="s"
            variant="glass"
            label={<T id="Use cases" />}
            theme="dark"
          />
        }
        aria-label="menu"
        items={[
          <Link
            to="/blog/education/"
            key="educ"
            activeClassName={styles.activePage}
          >
            <Item label={<T id="Education" />} />
          </Link>,
          <Link
            to="/blog/events/"
            key="evnt"
            activeClassName={styles.activePage}
          >
            <Item label={<T id="Events & conf" />} />
          </Link>,
          <Link
            to="/blog/business/"
            key="corp"
            activeClassName={styles.activePage}
          >
            <Item label={<T id="Business" />} />
          </Link>,
          <Link
            to="/blog/work-remotely/"
            key="workr"
            activeClassName={styles.activePage}
          >
            <Item label={<T id="Work remotely" />} />
          </Link>,
        ]}
      />
      {/* {joinButton('m')} */}
      <div className="visibleForNotSignedIn">
        <LoginModal
          trigger={
            <Button
              label={<T id="Sign in" />}
              variant="primary"
              size="m"
              theme="dark"
              style={{ minWidth: '127px' }}
            />
          }
        />
      </div>
      <Button
        label={<T id="Dashboard" />}
        variant="primary"
        size="m"
        theme="dark"
        onClick={() => (window.location.href = getAppSiteUrl())}
        className="visibleForSignedIn"
      />

      {/* <span className="hypersayFirstName">
            <span>
              <T id="you" />
            </span>
          </span>
          ! */}
    </Stack>
  );
};

const MenuMobile = () => {
  // const currentUrl = getCurrentWebsiteUrl();
  return (
    <div className={`${styles.header} ${styles.forMobileOnly}`}>
      <HypersayLogo />
      <Stack direction="horizontal" gap=".5rem">
        {/* {joinButton('s')} */}
        <div className="visibleForNotSignedIn">
          <LoginModal
            trigger={
              <Button
                label={<T id="Sign in" />}
                variant="primary"
                size="s"
                theme="dark"
                style={{ minWidth: '127px' }}
              />
            }
          />
        </div>
        <Button
          label={<T id="Dashboard" />}
          variant="primary"
          size="s"
          theme="dark"
          onClick={() => (window.location.href = getAppSiteUrl())}
          className="visibleForSignedIn"
        />
        <Dropdown
          trigger={
            <Button
              size="s"
              colors={['--splashed-white', '--mouse-gray']}
              icon={<Icons.More size="m" />}
              theme="dark"
              outline={true}
            />
          }
          aria-label="menu"
          items={[
            <Link to="/" key="home">
              <Item label={<T id="Home" />} />
            </Link>,
            <Link to="/blog/guide/" key="guide">
              <Item label={<T id="Guide" />} />
            </Link>,
            <Link to="/blog/tips/" key="tips">
              <Item label={<T id="Tips&Tricks" />} />
            </Link>,
            <Link to="/blog/education/" key="educ">
              <Item label={<T id="How to: Education" />} />
            </Link>,
            <Link to="/blog/events/" key="evnt">
              <Item label={<T id="How to: Events & conf" />} />
            </Link>,
            <Link to="/blog/business/" key="corp">
              <Item label={<T id="How to: Business" />} />
            </Link>,
            <Link to="/blog/work-remotely/" key="tips">
              <Item label={<T id="Work remotely" />} />
            </Link>,
          ]}
        />
      </Stack>
    </div>
  );
};

const Header = () => {
  return (
    <>
      <MenuDesktop />
      <MenuMobile />
    </>
  );
};

export default Header;
