import React, { ReactNode } from 'react';

import Header from '../../components/blog/header';
import Footer from '../../components/footer';
import useWindowDimensions from '../../components/getWindowDimensions';

import { ContainerFullWidth, ContainerMainGrid } from '@hypersay/ui';

import { Registered } from '../../components/registered-header';
import './index.scss';

export interface LayoutBlogProps {
  children: ReactNode;
  className?: string;
}
export const WindowSizeContext = React.createContext({ width: 0, height: 0 });

const LayoutBlog = ({ children, className }: LayoutBlogProps) => {
  const { width, height } = useWindowDimensions();

  return (
    <WindowSizeContext.Provider value={{ width, height }}>
      <ContainerMainGrid
        sticky={true}
        topmenu={<Header />}
        body={
          <ContainerFullWidth className={className}>
            {children}
          </ContainerFullWidth>
        }
        footer={<Footer />}
        className="containerBlog"
      />
      <Registered />
    </WindowSizeContext.Provider>
  );
};

export default LayoutBlog;
