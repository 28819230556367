import { Button } from '@hypersay/ui';
import React from 'react';

import T from '../components/T';

import { Link as MyLink } from './Link';
import * as styles from './howToHypersayMenu.module.scss';

const Link: React.FC<{ tabIndex: string; to: string }> = (props) => (
  // @ts-ignore
  <MyLink {...props} />
);

const HowToHypersayMenu: React.FC<{ active: string }> = ({ active }) => (
  <div className={styles.menu}>
    <Link to="/blog/education" tabIndex="-1">
      <Button
        label={<T id="Education" />}
        size="m"
        rounded="0px"
        className={styles.first}
        theme={active === 'education' ? 'light' : 'dark'}
        variant={active === 'education' ? 'primary' : 'default'}
      />
    </Link>
    <Link to="/blog/events" tabIndex="-1">
      <Button
        label={<T id="Events" />}
        size="m"
        rounded="0px"
        theme={active === 'events' ? 'light' : 'dark'}
        variant={active === 'events' ? 'primary' : 'default'}
      />
    </Link>
    <Link to="/blog/business" tabIndex="-1">
      <Button
        label={<T id="Business" />}
        size="m"
        rounded="0px"
        theme={active === 'business' ? 'light' : 'dark'}
        variant={active === 'business' ? 'primary' : 'default'}
      />
    </Link>
    <Link to="/blog/work-remotely" tabIndex="-1">
      <Button
        label={<T id="Work remotely" />}
        size="m"
        rounded="0px"
        className={styles.last}
        theme={active === 'work-remotely' ? 'light' : 'dark'}
        variant={active === 'work-remotely' ? 'primary' : 'default'}
      />
    </Link>
  </div>
);

export default HowToHypersayMenu;
