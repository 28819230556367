import { Button, ContainerMaxWidth } from '@hypersay/ui';
import React from 'react';

import HowToHypersayMenu from '../../components/howToHypersayMenu';
import SEO from '../../components/seo';
import T from '../../components/T';
import LayoutBlog from '../../layouts/blog';

import { withPrefix } from 'gatsby';
import * as styles from './howtohypersay.module.scss';

const Events = () => (
  <LayoutBlog>
    <SEO
      title="How to Hypersay - Use cases - Events"
      description="Hypersay helps keynote speakers better engage and understand their audiences. Embedded feedback mechanism and session analytics mean you can track how good your presentation was."
      keywords={[
        'live presentation',
        'interactive presentations',
        'event organiser',
        'conference speaker',
        'keynote speaker',
        'live polling',
        'audience engagement',
        'presentation analytics',
        'powerpoint',
        'google slides',
        'presentation analytics',
        'analytics',
      ]}
    />
    <ContainerMaxWidth className={styles.container} maxWidth="1400px">
      <T tagName="h1" id="Hypersay for Events" />
      <T
        tagName="p"
        id="Our 180K users enjoy Hypersay for very different reasons, based on their professions. Below you can find several use cases."
      />
      <HowToHypersayMenu active="events" />
      <div className={styles.item}>
        <div className={styles.image}>
          <a
            href="https://hypersay.events/?utm_source=hypersaycom"
            style={{ textDecoration: 'none' }}
            target="_blank"
          >
            <img
              src={withPrefix('/logos/logo-hse-vertical.svg')}
              alt="questions"
            />
          </a>
        </div>
        <div className={styles.text}>
          <T tagName="h2" id="Built for organisers. Loved by participants" />
          {/* <T tagName="p" id="We've launched a new product" /> */}
          <div style={{ marginBottom: '1em' }}>
            <T
              tagName="p"
              id="We have designed an entirely new product specifically to support onlive virtual events. It is caled Hypersay Events and it is a second-generation digital events platform, offering unique tools to create emotional connections, for engaged and involved participants."
            />
          </div>
          <a
            href="https://hypersay.events/?utm_source=hypersaycom"
            style={{ textDecoration: 'none' }}
            target="_blank"
          >
            <Button
              label={<T id="Visit Hypersay.events" />}
              variant="default"
              colors={['--splashed-white', '--valencia']}
              size="m"
              theme="dark"
            />
          </a>
        </div>
      </div>
      {/* <T
        tagName="p"
        id="Our 180K users enjoy Hypersay for very different reasons, based on their professions. Below you can find several use cases."
      />
      <div className={styles.item}>
        <div className={styles.image}>
          <img
            src={withPrefix('/images/use-cases/questions.png')}
            alt="questions"
          />
        </div>
        <div className={styles.text}>
          <T tagName="h2" id="No, they want to hear you because you listen" />
          <T tagName="p" id="Imagine if TED listened?" />
          <T
            tagName="p"
            id="One way dialogues are soooo yesterday. The capacity to listen to your audience empowers you as a speaker to control your message and enhance your reputation. The functionality within Hypersay allows you to unleash your audiences' voice and get them speaking about you and your content. Do not lecture them. Listen to them and they will love you."
          />
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.text}>
          <T tagName="h2" id="Do you share?" />
          <T
            tagName="p"
            id="If you speak at events and conferences you inherently know that your audience has come to ‘look, listen and learn’. But do you share?"
          />
          <T
            tagName="p"
            id="Audiences are frequently frustrated by the absence, or late sharing, of slide presentations they attended at paid for events and conferences. Hypersay allows you to easily share your slides with your audience, build your reputation with them and ensure your content is consumed.  Our full suite of interactive functionality allows speakers to engage with their audiences before, during and after the presentation."
          />
        </div>
        <div className={styles.image}>
          <img src={withPrefix('/images/use-cases/share.png')} alt="share" />
        </div>
      </div> */}
    </ContainerMaxWidth>
  </LayoutBlog>
);

export default Events;
